import "./Loading.scss"
const Loading = () => {
    return(
        <div className="loading-to-center">
            <div className="Loading">
                <div>

                </div>
            </div>
        </div>
    )
}

export default Loading;