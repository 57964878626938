import './WalletPage.scss'
const WalletPage=()=>{
    return (
        <div className="WalletPage">
                Welcome to WalletPage!
        </div>
    )
}

export default WalletPage;
